<template>
  <v-carousel v-model="model"  height="600" class="mt-0 pa-0">
    <v-carousel-item
      v-for="(item,i) in banners"
      :key="i"
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
        <v-img :src="item.imagem" height="100%" width="100%" alt="">
            <v-container
            >
                <v-row
                    style="margin: 305px 0 0 0"
                    align="center"
                    justify="center"
                >
                    <v-col 
                        md="8"
                        offset-md="4"
                    >
                        <v-sheet  color="#f1aa48" class="pa-5">
                            <h1 class="pt-20 pb-20 mt-20 mb-20 ml-10 mr-10">{{item.titulo}}</h1>
                            <h2 class="pt-20 pb-20 mt-20 mb-20 ml-10 mr-10">{{item.subtitulo}}</h2>
                            <v-btn
                                outlined
                                class="pa-20 ma-20 mb-20 mt-5 ml-10 mb-2 mr-10"
                            >{{item.btn}}
                              <v-icon>{{item.icon}}</v-icon>
                            </v-btn>
                            
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </v-img>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
import { mapState } from 'vuex'
  export default {
    name:'Banner',
    data: () => ({
      model: 0,
      
    }),
    computed:{
        ...mapState(['banners']),
    },
    mounted(){
            var data = JSON.stringify({"banner":1,"posicao":1});
            var config = {
                method: 'get',
                url: 'home/slide/',
                data : data
            };
            //realiza o login
            this.$store.dispatch('listaBanner',config)

    }
  }
</script>